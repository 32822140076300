import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';

const secondPulse = keyframes`
  20% {box-shadow: 0 0 0 0 #55C1FF;}
  40% {box-shadow: transparent 0px 0px 0px 14px}
`;

const thirdPulse = keyframes`
  40% {box-shadow: 0 0 0 0 #55C1FF;}
  60% {box-shadow: transparent 0px 0px 0px 14px}
`;

export const ChallengeSelection = () => {
  const { pushNextRoute } = useAppRouter('/checkout');
  const { findProductById } = useProducts();
  const { updateUser } = useAppLead();
  const analytics = useAnalytics();

  const handleClick = (productId: number) => {
    const product = findProductById(String(productId));

    updateUser({ selectedPlan: product });

    if (product) {
      analytics.log('plans_select', { category: product?.name, label: product.name });
    }

    pushNextRoute();
  };

  return (
    <>
      <Box
        border='2px solid'
        borderColor='#715AFF'
        borderRadius='0.5rem'
        width={{ base: '100%', md: '60%' }}
        backgroundColor='system.white'
        overflow='hidden'
      >
        <Box padding='0.5rem' backgroundColor='#715AFF'>
          <Text color='white' fontWeight={700} fontSize='14px' textAlign='center'>
            MOST POPULAR
          </Text>
        </Box>
        <Box padding={{ base: '0.75rem 1rem', md: '1.25rem 1.5rem' }}>
          <Flex
            gap={{ base: '1.5rem', md: '1.5rem' }}
            justifyContent='space-between'
            alignItems='center'
            marginBottom='0.5rem'
          >
            <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
              <Box>
                <Text
                  fontWeight={700}
                  fontSize={{ base: '20px', md: '24px' }}
                  marginBottom={{ base: '0.25rem', md: '0.375rem' }}
                  lineHeight='120%'
                  color='#060654'
                >
                  3-months plan with 28-days money-back challenge
                </Text>
              </Box>
            </Flex>
            <Box textAlign='center'>
              <Text fontSize={{ base: '18px', md: '24px' }} color='#060654' lineHeight='120%'>
                4.99 USD
              </Text>
              <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                per week
              </Text>
            </Box>
          </Flex>
          <Text fontSize='14px' color='rgba(0, 0, 0, 0.7)' marginBottom='1rem'>
            Commit to your goals. Complete <b>28 days challenge</b> in 3 months, <b>get your money back</b> and use our
            program for free.
            <br />
            <br />
            If not completed, we will bill you 59.88 USD every 3 months until canceled. Cancel any time.
          </Text>
          <Button
            width='100%'
            gap='1rem'
            onClick={() => handleClick(12)}
            animation={`10s ease 0s infinite normal none running ${secondPulse}`}
            backgroundColor='#55C1FF'
            color='white'
            _hover={{
              backgroundColor: '#55C1FF',
            }}
            fontWeight={700}
            cursor='pointer'
          >
            Claim my plan
          </Button>
        </Box>
      </Box>
      <Box
        padding={{ base: '1rem', md: '1.25rem 1.5rem' }}
        border='2px solid'
        borderColor='#efefef'
        borderRadius='0.5rem'
        width={{ base: '100%', md: '40%' }}
        backgroundColor='system.white'
      >
        <Flex
          gap={{ base: '1.5rem', md: '1.5rem' }}
          justifyContent='space-between'
          alignItems='center'
          marginBottom='1rem'
        >
          <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
            <Box>
              <Text
                fontWeight={700}
                fontSize={{ base: '20px', md: '24px' }}
                marginBottom={{ base: '0.25rem', md: '0.5rem' }}
                lineHeight='100%'
                color='#060654'
              >
                1 month plan
              </Text>
              <Flex gap='0.5rem' alignItems='center'>
                <Text fontSize={{ base: '14px', md: '18px' }} color='system.error' textDecoration='line-through'>
                  $79.99
                </Text>
                <Text fontSize={{ base: '16px', md: '18px' }} color='rgba(5, 5, 23, 0.64)'>
                  $29.99
                </Text>
              </Flex>
              <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                Billed every month
              </Text>
            </Box>
          </Flex>
          <Box textAlign='center'>
            <Text fontSize={{ base: '18px', md: '24px' }} color='#060654' lineHeight='120%'>
              7.49 USD
            </Text>
            <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
              per week
            </Text>
          </Box>
        </Flex>
        <Button
          width='100%'
          gap='1rem'
          onClick={() => handleClick(13)}
          animation={`10s ease 0s infinite normal none running ${thirdPulse}`}
          backgroundColor='#55C1FF'
          color='white'
          _hover={{
            backgroundColor: '#55C1FF',
          }}
          fontWeight={700}
          cursor='pointer'
        >
          Claim my plan
        </Button>
      </Box>
    </>
  );
};
