import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { generalUtils, useHydration } from '@shared/common';
import { useTranslation } from 'next-i18next';

import ArrowSVG from '~/assets/icons/arrow.svg';
import CheckSVG from '~/assets/icons/check.svg';
import { ChallengeCompletionDate } from '~/components/ChallengeCompletionDate';
import { TransText } from '~/components/TransText';
import { useAnalytics } from '~/hooks/useAnalytics';
import { SINGLE_PRODUCT_COUPONS, useAppParams } from '~/hooks/useAppParams';
import { useFreshState } from '~/hooks/useFreshState';

import { Product, useAppLead } from '../../hooks/useAppLead';
import { useAppRouter } from '../../hooks/useAppRouter';
import { useProducts } from '../../hooks/useProducts';

const firstPulse = keyframes`
  0% {box-shadow: 0 0 0 0 #3FA134;}
  20% {box-shadow: transparent 0px 0px 0px 14px}
`;

export const PlanSelectionChoice = () => {
  const { coupon } = useAppParams();
  const isHydrated = useHydration();
  const { t } = useTranslation();
  const { pushNextRoute, funnelName } = useAppRouter('/checkout');
  const { findProductById, findPriceByCurrency, initialPlan } = useProducts();
  const [activePlan, setActivePlan] = useFreshState<string>(initialPlan[6]);

  const { updateUser } = useAppLead();
  const analytics = useAnalytics();

  const handleClick = (newProduct?: Product) => {
    const product = newProduct || findProductById(activePlan);

    if (product) {
      updateUser({ selectedPlan: product });

      analytics.log('plans_select', { category: product.title, label: product.key });

      pushNextRoute(
        coupon && SINGLE_PRODUCT_COUPONS.includes(coupon) && ['22', '23'].includes(product.id)
          ? { queryParams: { coupon: undefined } }
          : funnelName === 'challenge'
            ? { queryParams: { coupon: 'freeplan' } }
            : funnelName === 'cortisol-bf'
              ? { pathname: `/${funnelName}/checkout` }
              : undefined,
      );
    }
  };

  return isHydrated ? (
    <>
      {(() => {
        const productId = initialPlan[3];
        const product = findProductById(productId);
        const price = findPriceByCurrency(product?.trial?.prices ?? product?.prices);

        return (
          <Box
            border='2px solid'
            borderRadius='0.5rem'
            width='100%'
            padding={{ base: '1rem', md: '1rem 1.5rem' }}
            position='relative'
            borderColor={activePlan === productId ? '#3FA134' : '#DBDDD8'}
            backgroundColor={activePlan === productId ? '#F4FFF3' : 'system.white'}
            onClick={() => setActivePlan(productId)}
            cursor='pointer'
            transition='all 200ms'
            marginBottom={{ base: '10px', md: '2px' }}
          >
            {product ? (
              <Flex alignItems='center' gap='1rem'>
                <Flex>
                  {activePlan === productId ? (
                    <CheckSVG width='24px' height='24px' fill='#3FA134' />
                  ) : (
                    <Box width='24px' height='24px' border='2px solid #D1D3CD' borderRadius='1.5rem' />
                  )}
                </Flex>
                <Flex
                  flex={1}
                  gap={{ base: '1.5rem', md: '1.5rem' }}
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                    <Box>
                      {coupon && !SINGLE_PRODUCT_COUPONS.includes(coupon) ? (
                        <TransText
                          transKey='common:coupon_applied'
                          display='inline-block'
                          fontSize='10px'
                          padding='0.325rem 0.625rem'
                          bg='#97d8f1'
                          lineHeight='100%'
                          borderRadius='0.5rem'
                          marginBottom='0.5rem'
                          color='#054586'
                          values={{ value: coupon.toUpperCase() }}
                        ></TransText>
                      ) : null}
                      <TransText
                        transKey='common:x_months_plan'
                        fontWeight={700}
                        fontSize={{ base: '20px', md: '24px' }}
                        marginBottom={{ base: '0.25rem', md: '0.375rem' }}
                        lineHeight='100%'
                        values={{
                          interval: product.plan.interval,
                          count: product.plan.interval,
                        }}
                        color='primaryDark'
                      ></TransText>
                      <Flex gap='0.5rem' alignItems='center '>
                        <Text fontSize={{ base: '12px', md: '14px' }} color='#C73E1D' textDecoration='line-through'>
                          {generalUtils.formatPrice(price.price, price.currency)}
                        </Text>
                        <Text fontSize={{ base: '12px', md: '14px' }} color='rgba(5, 5, 23, 0.64)'>
                          {generalUtils.formatPrice(price.finalPrice, price.currency)}
                        </Text>
                      </Flex>
                      {/* <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                  Billed every 6 months
                </Text> */}
                    </Box>
                  </Flex>
                  <Box textAlign='center'>
                    <Text
                      textDecoration='line-through'
                      fontSize='14px'
                      color={activePlan === productId ? '#C73E1D' : 'rgba(5, 5, 23, 0.64)'}
                    >
                      {generalUtils.formatPrice(price.price / (product.plan.interval * 30), price.currency)}
                    </Text>
                    <Text fontSize={{ base: '24px', md: '30px' }} fontWeight={900} lineHeight='120%'>
                      {generalUtils.formatPrice(price.finalPrice / (product.plan.interval * 30), price.currency)}
                    </Text>
                    <TransText transKey='common:per_day' color='rgba(5, 5, 23, 0.64)' fontSize='14px'></TransText>
                  </Box>
                </Flex>
              </Flex>
            ) : (
              <Flex justifyContent='center'>
                <Spinner />
              </Flex>
            )}
          </Box>
        );
      })()}
      {(() => {
        const productId = initialPlan[6];
        const product = findProductById(productId);
        const price = findPriceByCurrency(product?.trial?.prices ?? product?.prices);

        return (
          <Box
            position='relative'
            padding={{ base: '1rem', md: '1rem 1.5rem' }}
            border='2px solid'
            borderRadius='0.5rem'
            width='100%'
            borderColor={activePlan === productId ? '#3FA134' : '#DBDDD8'}
            backgroundColor={activePlan === productId ? '#F4FFF3' : 'system.white'}
            onClick={() => setActivePlan(productId)}
            cursor='pointer'
            transition='all 200ms'
          >
            {product ? (
              <Flex alignItems='center' gap='1rem'>
                <Flex>
                  {activePlan === productId ? (
                    <CheckSVG width='24px' height='24px' fill='#3FA134' />
                  ) : (
                    <Box width='24px' height='24px' border='2px solid #D1D3CD' borderRadius='1.5rem' />
                  )}
                </Flex>
                <Flex
                  flex={1}
                  gap={{ base: '1.5rem', md: '1.5rem' }}
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Flex justifyContent='center' position='absolute' top='-0.75rem' left='0' right='0' zIndex={10}>
                    <TransText
                      transKey={
                        funnelName === 'cortisol-bf' ? 'plans:campaigns:black_friday_deal' : 'common:best_value_off'
                      }
                      display='inline-block'
                      padding='0.25rem 1rem'
                      borderRadius='4px'
                      backgroundColor={funnelName === 'cortisol-bf' ? 'black' : '#FFD442'}
                      fontWeight={500}
                      fontSize='11px'
                      textAlign='center'
                      values={{ value: 100 - Math.round((price.finalPrice / price.price) * 100) }}
                      color={funnelName === 'cortisol-bf' ? 'white' : 'black'}
                    ></TransText>
                  </Flex>
                  <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                    <Box>
                      {coupon ? (
                        <TransText
                          transKey='common:coupon_applied'
                          display='inline-block'
                          fontSize='10px'
                          padding='0.325rem 0.625rem'
                          bg='#97d8f1'
                          lineHeight='100%'
                          borderRadius='0.5rem'
                          marginBottom='0.5rem'
                          color='#054586'
                          values={{ value: coupon.toUpperCase() }}
                        ></TransText>
                      ) : null}
                      <TransText
                        transKey='common:x_months_plan'
                        fontWeight={700}
                        fontSize={{ base: '20px', md: '24px' }}
                        marginBottom={{ base: '0.25rem', md: '0.375rem' }}
                        lineHeight='100%'
                        color='primaryDark'
                        values={{
                          interval: product.plan.interval,
                          count: product.plan.interval,
                        }}
                      ></TransText>
                      <Flex gap='0.5rem' alignItems='center'>
                        <Text fontSize={{ base: '12px', md: '14px' }} color='#C73E1D' textDecoration='line-through'>
                          {generalUtils.formatPrice(price.price, price.currency)}
                        </Text>
                        <Text fontSize={{ base: '12px', md: '14px' }} color='rgba(5, 5, 23, 0.64)'>
                          {generalUtils.formatPrice(price.finalPrice, price.currency)}
                        </Text>
                      </Flex>
                      {/* <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                  Billed every 3 months
                </Text> */}
                    </Box>
                  </Flex>
                  <Box textAlign='center'>
                    <Text
                      textDecoration='line-through'
                      fontSize='14px'
                      color={activePlan === productId ? '#C73E1D' : 'rgba(5, 5, 23, 0.64)'}
                    >
                      {generalUtils.formatPrice(price.price / (product.plan.interval * 30), price.currency)}
                    </Text>
                    <Text fontSize={{ base: '24px', md: '30px' }} fontWeight={900} lineHeight='120%'>
                      {generalUtils.formatPrice(price.finalPrice / (product.plan.interval * 30), price.currency)}
                    </Text>
                    <TransText transKey='common:per_day' color='rgba(5, 5, 23, 0.64)' fontSize='14px'></TransText>
                  </Box>
                </Flex>
              </Flex>
            ) : (
              <Flex justifyContent='center'>
                <Spinner />
              </Flex>
            )}
          </Box>
        );
      })()}
      {(() => {
        const productId = initialPlan[1];
        const product = findProductById(productId);
        const price = findPriceByCurrency(product?.trial?.prices ?? product?.prices);

        return (
          <Box
            padding={{ base: '1rem', md: '1.25rem 1.5rem' }}
            border='2px solid'
            borderRadius='0.5rem'
            width='100%'
            borderColor={activePlan === productId ? '#3FA134' : '#DBDDD8'}
            backgroundColor={activePlan === productId ? '#F4FFF3' : 'system.white'}
            onClick={() => setActivePlan(productId)}
            cursor='pointer'
            transition='all 200ms'
          >
            {product ? (
              <>
                <Flex alignItems='center' gap='1rem'>
                  <Flex>
                    {activePlan === productId ? (
                      <CheckSVG width='24px' height='24px' fill='#3FA134' />
                    ) : (
                      <Box width='24px' height='24px' border='2px solid #D1D3CD' borderRadius='1.5rem' />
                    )}
                  </Flex>

                  <Flex
                    flex={1}
                    gap={{ base: '1.5rem', md: '1.5rem' }}
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                      <Box>
                        {coupon && !SINGLE_PRODUCT_COUPONS.includes(coupon) ? (
                          <TransText
                            transKey='common:coupon_applied'
                            display='inline-block'
                            fontSize='10px'
                            padding='0.325rem 0.625rem'
                            bg='#97d8f1'
                            lineHeight='100%'
                            borderRadius='0.5rem'
                            marginBottom='0.5rem'
                            color='#054586'
                            values={{ value: coupon.toUpperCase() }}
                          ></TransText>
                        ) : null}
                        <TransText
                          transKey='common:x_months_plan'
                          fontWeight={700}
                          fontSize={{ base: '20px', md: '24px' }}
                          marginBottom={{ base: '0.25rem', md: '0.375rem' }}
                          lineHeight='100%'
                          color='primaryDark'
                          values={{
                            interval: product.plan.interval,
                            count: product.plan.interval,
                          }}
                        ></TransText>
                        <Flex gap='0.5rem' alignItems='center'>
                          <Text fontSize={{ base: '12px', md: '14px' }} color='#C73E1D' textDecoration='line-through'>
                            {generalUtils.formatPrice(price.price, price.currency)}
                          </Text>
                          <Text fontSize={{ base: '12px', md: '14px' }} color='rgba(5, 5, 23, 0.64)'>
                            {generalUtils.formatPrice(price.finalPrice, price.currency)}
                          </Text>
                        </Flex>
                        {/* <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                  Billed every month
                </Text> */}
                      </Box>
                    </Flex>
                    <Box textAlign='center'>
                      <Text
                        textDecoration='line-through'
                        fontSize='14px'
                        color={activePlan === productId ? '#C73E1D' : 'rgba(5, 5, 23, 0.64)'}
                      >
                        {generalUtils.formatPrice(price.price / (product.plan.interval * 30), price.currency)}
                      </Text>
                      <Text fontSize={{ base: '24px', md: '30px' }} fontWeight={900} lineHeight='120%'>
                        {generalUtils.formatPrice(price.finalPrice / (product.plan.interval * 30), price.currency)}
                      </Text>
                      <TransText transKey='common:per_day' color='rgba(5, 5, 23, 0.64)' fontSize='14px'></TransText>
                    </Box>
                  </Flex>
                </Flex>
              </>
            ) : (
              <Flex justifyContent='center'>
                <Spinner />
              </Flex>
            )}
          </Box>
        );
      })()}
      <Button
        width='100%'
        animation={`10s ease 0s infinite normal none running ${firstPulse}`}
        gap='1rem'
        marginBottom='0.5rem'
        onClick={() => handleClick()}
      >
        <Text color='white'>{t('common:claim_my_plan')}</Text>
        <ArrowSVG fill='white' />
      </Button>
      <ChallengeCompletionDate />
    </>
  ) : null;
};
