import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { generalUtils } from '@shared/common';
import { useTranslation } from 'next-i18next';

import ArrowSVG from '~/assets/icons/arrow.svg';
import { ChallengeCompletionDate } from '~/components/ChallengeCompletionDate';
import { TransText } from '~/components/TransText';
import { config } from '~/config';
import { useAnalytics } from '~/hooks/useAnalytics';

import { useAppLead } from '../../hooks/useAppLead';
import { useAppRouter } from '../../hooks/useAppRouter';
import { useProducts } from '../../hooks/useProducts';

const firstPulse = keyframes`
  0% {box-shadow: 0 0 0 0 #3FA134;}
  20% {box-shadow: transparent 0px 0px 0px 14px}
`;

export const PlanSelectionReport = () => {
  const { t } = useTranslation();
  const { pushNextFunnelRoute } = useAppRouter('/checkout');
  const { findProductById, findPriceByCurrency } = useProducts();

  const { updateUser } = useAppLead();
  const analytics = useAnalytics();
  const product = findProductById('127');
  const price = findPriceByCurrency(product?.trial?.prices ?? product?.prices);

  const handleClick = () => {
    if (product) {
      updateUser({ selectedPlan: product });

      analytics.log('plans_select', { category: product.title, label: product.key });

      pushNextFunnelRoute();
    }
  };

  return product ? (
    <>
      <Box
        position='relative'
        padding={{ base: '1.25rem 1rem 1rem 1rem', md: '1.5rem 1.5rem 1rem 1.5rem' }}
        border='2px solid'
        borderRadius='0.5rem'
        width='100%'
        borderColor='#3FA134'
        backgroundColor='#F4FFF3'
        cursor='pointer'
        transition='all 200ms'
      >
        <Flex flex={1} gap={{ base: '1.5rem', md: '1.5rem' }} justifyContent='space-between' alignItems='center'>
          <Flex justifyContent='center' position='absolute' top='-0.75rem' left='0' right='0' zIndex={10}>
            <TransText
              transKey='common:most_popular_off'
              display='inline-block'
              padding='0.25rem 1rem'
              borderRadius='4px'
              backgroundColor='#FFD442'
              fontWeight={500}
              fontSize='11px'
              textAlign='center'
              values={{ value: 100 - Math.round((price.finalPrice / price.price) * 100) }}
              color='black'
            ></TransText>
          </Flex>
          <Box>
            <Text
              fontWeight={{ base: 700, md: 900 }}
              fontSize={{ base: '20px', md: '26px' }}
              marginBottom='0.25rem'
              lineHeight={{ base: '120%', md: '115%' }}
              color='black'
              fontFamily='heading'
            >
              {product.title}
            </Text>
            <Text fontSize='14px' color='rgba(0, 0, 0, 0.7)'>
              7-Day Full Access
            </Text>
          </Box>
          <Box textAlign='center'>
            <Text fontSize={{ base: '20px', md: '24px' }} color='black' fontWeight={900} lineHeight='120%'>
              {generalUtils.formatPrice(price.finalPrice, price.currency)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Button
        width='100%'
        animation={`10s ease 0s infinite normal none running ${firstPulse}`}
        gap='1rem'
        marginBottom='0.5rem'
        onClick={() => handleClick()}
      >
        <Text color='white'>Get my plan</Text>
        <ArrowSVG fill='white' />
      </Button>
      <ChallengeCompletionDate />
      <Text fontSize='11px' color='rgba(0, 0, 0, 0.6)' textAlign='center'>
        {t('checkout:trial_disclaimer_copy', {
          value: generalUtils.formatPrice(findPriceByCurrency(product.prices).finalPrice, price.currency),
          interval: product?.plan?.interval,
          projectName: config.name,
        })}
      </Text>
    </>
  ) : (
    <Flex justifyContent='center'>
      <Spinner />
    </Flex>
  );
};
