import { Button, Flex } from '@chakra-ui/react';
import Link from 'next/link';
import { FC } from 'react';

import FemaleSVG from '~/assets/icons/female.svg';
import MaleSVG from '~/assets/icons/male.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useTracking } from '~/hooks/useTracking';
import { Funnel } from '~/types';

import { TransText } from './TransText';

export const GenderSelection: FC<{ funnelName?: Funnel }> = ({ funnelName }) => {
  const { updateQuizAnswers } = useAppLead();
  const { quizQuestionAnswer } = useTracking();
  const { getNextFunnelUrl } = useAppRouter('/quiz');

  const handleSelection = (gender: 'female' | 'male') => {
    updateQuizAnswers({ gender });

    quizQuestionAnswer({ category: '01_gender', label: gender });
  };

  return (
    <Flex gap={{ base: '0.5rem', md: '1rem' }} width='100%' overflowWrap='anywhere'>
      <Link href={getNextFunnelUrl(funnelName ? { funnelName } : undefined)}>
        <Button
          display='inline-block'
          variant='secondary'
          flex={1}
          gap='1rem'
          onClick={() => handleSelection('male')}
          textTransform='uppercase'
          whiteSpace='normal'
          padding={{ base: '1rem', md: '1rem 1.5rem' }}
        >
          <TransText transKey='common:weight_loss_for_men'></TransText>
        </Button>
      </Link>
      <Link href={getNextFunnelUrl(funnelName ? { funnelName } : undefined)}>
        <Button
          display='inline-block'
          flex={1}
          gap='1rem'
          onClick={() => handleSelection('female')}
          textTransform='uppercase'
          whiteSpace='normal'
          padding={{ base: '1rem', md: '1rem 1.5rem' }}
        >
          <TransText transKey='common:weight_loss_for_women'></TransText>
        </Button>
      </Link>
    </Flex>
  );
};

export const GenderSelectionWithIcons = () => {
  const { updateQuizAnswers } = useAppLead();
  const { quizQuestionAnswer } = useTracking();
  const { pushNextRoute } = useAppRouter('/quiz');

  const handleSelection = (gender: 'female' | 'male') => {
    updateQuizAnswers({ gender });

    quizQuestionAnswer({ category: '01_gender', label: gender });

    pushNextRoute();
  };

  return (
    <Flex gap={{ base: '0.5rem', md: '1rem' }} width='100%'>
      <Button
        display='flex'
        flex={1}
        gap='1rem'
        onClick={() => handleSelection('male')}
        textTransform='uppercase'
        whiteSpace='normal'
        bg='black'
        color='white'
        borderRadius='2rem'
        _hover={{
          bg: 'black',
        }}
      >
        <MaleSVG />
        <TransText transKey='Male'></TransText>
      </Button>
      <Button
        display='flex'
        flex={1}
        gap='1rem'
        onClick={() => handleSelection('female')}
        textTransform='uppercase'
        bg='#F36404'
        color='white'
        borderRadius='2rem'
        _hover={{
          bg: '#F36404',
        }}
      >
        <FemaleSVG />
        <TransText transKey='Female'></TransText>
      </Button>
    </Flex>
  );
};
